<template>
  <v-dialog
    v-model="dialog"
    :persistent="disabledRule"
    max-width="600"
    transition="dialog-bottom-transition"
  >
    <v-overlay
      absolute
      :value="loading"
    >
      <v-progress-circular
        :size="50"
        color="white"
        indeterminate
      />
    </v-overlay>
    <v-card>
      <v-toolbar
        flat
        style="position: sticky; top: 0px; z-index: 1;"
      >
        <v-card-title
          class="font-weight-light"
        >
          {{ $t('actions|edit_categories') }}
        </v-card-title>
        <v-spacer />
        <v-icon
          size="24"
          class="mr-3"
          @click="closeDialog()"
        >
          mdi-close
        </v-icon>
      </v-toolbar>
      <v-card-text
        v-if="availableCategories.length"
        class="mt-3"
      >
        <div
          v-for="(item, i) in availableCategories"
          :key="i"
          class="mt-2"
          xs12
          md12
        >
          <v-text-field
            v-model="item.value"
            outlined
            dense
            :label="$t('mixed|category')"
            class="mx-4"
            :placeholder="item.value"
            :rules="[(v) => !!v || $t('expressions|text_required_field_cannot_be_empty')]"
            :append-icon="item.value ? 'mdi-close' : ''"
            :append-outer-icon="'mdi-trash-can'"
            @click:append="clearInput(i)"
            @click:append-outer="deleteCategories(item)"
          />
        </div>
      </v-card-text>
      <v-card-text
        v-else
      >
        {{ $t('mixed|no_available_categories') }}
      </v-card-text>
      <v-card-actions
        style="position: sticky; bottom: 0px; background-color: white;"
      >
        <v-spacer />
        <v-btn
          class="mb-3"
          color="primary"
          rounded
          small
          dense
          min-width="110"
          :disabled="disabledRule"
          @click="updateCategories(); closeDialog()"
        >
          {{ $t('actions|save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      disabledRule: true,
      myCaseModelsCategories: [],
      companyCaseModelsCategories: [],
      groupCaseModelsCategories: [],
      lavviraCaseModelsCategories: [],
      actualTab: null,
      error: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      caseForms: state => state.caseForms.caseForms,
      companyCaseForms: state => state.companyCaseForms.companyCaseForms,
      groupCaseForms: state => state.groupCaseForms.groupCaseForms,
      settings: state => state.settings.settings
    }),
    availableCategories () {
      let categories = []
      if (this.actualTab && this.actualTab.isMyTab) {
        categories = this.account.myCaseModelsCategories
      }
      if (this.actualTab && this.actualTab.isCompanyTab) {
        categories = this.company.companyCaseModelsCategories
      }
      if (this.actualTab && this.actualTab.group) {
        const index = this.company.groups.findIndex(gr => gr._id === this.actualTab.group._id)
        categories = this.company.groups[index].groupCaseModelsCategories
      }
      if (this.actualTab && this.actualTab.isLavviraTab) {
        categories = this.settings.lavviraSettings.lavviraCaseModelsCategories
      }
      return categories
    }
  },
  watch: {
    account: {
      handler (value) {
        this.myCaseModelsCategories = value.myCaseModelsCategories || {}
      },
      immediate: true
    },
    dialog (value) {
      if (value) {
        this.companyCaseModelsCategories = this.company.companyCaseModelsCategories
        if (this.actualTab && this.actualTab.group) {
          const index = this.company.groups.findIndex(gr => gr._id === this.actualTab.group._id)
          if (index !== -1) {
            this.groupCaseModelsCategories = this.company.groups[index].groupCaseModelsCategories
          }
        }
      }
    },
    company: {
      handler (value) {
        if (value) {
          this.companyCaseModelsCategories = value.companyCaseModelsCategories || {}
        }
        if (this.actualTab && this.actualTab.group) {
          const index = value.groups.findIndex(gr => gr._id === this.actualTab.group._id)
          if (index !== -1) {
            this.groupCaseModelsCategories = value.groups[index].groupCaseModelsCategories
          }
        }
      },
      immediate: true
    },
    settings: {
      handler (value) {
        this.lavviraCaseModelsCategories = value.lavviraSettings.lavviraCaseModelsCategories || {}
      },
      immediate: true
    },
    availableCategories: {
      handler (value) {
        if (value.length) {
          value.forEach(text => {
            if (text.value === '') this.disabledRule = true
            else this.disabledRule = false
          })
        } else {
          this.disabledRule = false
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateCaseForm: 'caseForms/updateCaseForm',
      updateCaseModelCategories: 'account/updateCaseModelCategories',
      updateCompanyCaseModelsCategories: 'company/updateCompanyCaseModelsCategories',
      updateGroupCaseModelsCategories: 'company/updateGroupCaseModelsCategories',
      deleteCaseModelCategories: 'account/deleteCaseModelCategories',
      deleteCaseModelCategoryInCaseModel: 'caseForms/deleteCaseModelCategoryInCaseModel',
      removeCompanyCaseModelCategories: 'company/removeCompanyCaseModelCategories',
      removeGroupCaseModelsCategories: 'company/removeGroupCaseModelsCategories',
      updateSettings: 'settings/updateSettings'
    }),
    async updateCategories () {
      if (this.actualTab && this.actualTab.isMyTab) {
        this.loading = true
        try {
          await this.updateCaseModelCategories({ myCaseModelsCategories: this.myCaseModelsCategories })
        } catch (error) {
          this.error = error
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      } else if (this.actualTab && this.actualTab.isCompanyTab) {
        this.loading = true
        const _id = this.actualTab.company._id
        const payload = {
          companyCaseModelsCategories: this.companyCaseModelsCategories
        }
        try {
          await this.updateCompanyCaseModelsCategories({ _id, payload })
        } catch (error) {
          this.error = error
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      } else if (this.actualTab && this.actualTab.isGroupTab) {
        this.loading = true
        const _id = this.actualTab.group._id
        const payload = {
          groupCaseModelsCategories: this.groupCaseModelsCategories
        }
        try {
          await this.updateGroupCaseModelsCategories({ _id, payload })
        } catch (error) {
          this.error = error
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      } else if (this.actualTab && this.actualTab.isLavviraTab) {
        this.loading = true
        const payload = {
          updateLavviraDBCaseModelsCategoriesContent: true,
          lavviraCaseModelsCategories: this.lavviraCaseModelsCategories
        }
        try {
          await this.updateSettings({ payload })
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      }
    },
    async deleteCategories (item) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure_you_want_to_delete_this_resource')
      })
      if (!res) return
      if (res) {
        if (this.actualTab && this.actualTab.isMyTab) {
          this.loading = true
          const payload = {
            myCaseModelsCategories: item.value
          }
          try {
            await this.deleteCaseModelCategories(payload)
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            this.loading = false
          }
        } else if (this.actualTab && this.actualTab.isCompanyTab) {
          this.loading = true
          const _id = this.actualTab.company._id
          const payload = {
            companyCaseModelsCategories: item.value
          }
          try {
            await this.removeCompanyCaseModelCategories({ _id, payload })
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            await this.deleteCategoryInCM(item)
            this.loading = false
          }
        } else if (this.actualTab && this.actualTab.isGroupTab) {
          this.loading = true
          const _id = this.actualTab.group._id
          const payload = {
            groupCaseModelsCategories: item.value
          }
          try {
            await this.removeGroupCaseModelsCategories({ _id, payload })
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            await this.deleteCategoryInCM(item)
            this.loading = false
          }
        } else if (this.actualTab && this.actualTab.isLavviraTab) {
          this.loading = true
          const payload = {
            lavviraCaseModelCategoryToBeDeleted: true,
            lavviraCaseModelCategory: item.value
          }
          try {
            await this.updateSettings({ payload })
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            await this.deleteCategoryInCM(item)
            this.loading = false
          }
        }
      }
    },
    async deleteCategoryInCM (item) {
      let idArray = []
      if (this.actualTab && this.actualTab.isMyTab) {
        this.loading = true
        this.caseForms.forEach(cf => {
          if (cf.category === item.value) {
            idArray.push(cf._id)
          }
        })
        if (idArray.length) {
          idArray.forEach(_id => {
            try {
              const payload = {
                category: item.value
              }
              this.deleteCaseModelCategoryInCaseModel({
                _id,
                payload
              })
            } catch (e) {
              this.error = e
              console.error(this.error, 'error')
            } finally {
              this.loading = false
            }
          })
        }
      } else if (this.actualTab && this.actualTab.isCompanyTab) {
        this.companyCaseForms.forEach(cf => {
          if (cf.category === item.value) {
            idArray.push(cf._id)
          }
        })
        if (idArray.length) {
          idArray.forEach(_id => {
            try {
              const payload = {
                category: item.value
              }
              this.deleteCaseModelCategoryInCaseModel({
                _id,
                payload
              })
            } catch (e) {
              this.error = e
              console.error(this.error, 'error')
            } finally {
              this.loading = false
            }
          })
        }
      } else if (this.actualTab && this.actualTab.isGroupTab) {
        this.groupCaseForms.forEach(gcf => {
          gcf.sharedWith.groups.forEach(gr => {
            if (gr._id === this.actualTab.group._id && gcf.category === item.value) {
              idArray.push(gcf._id)
            }
          })
        })
        if (idArray.length) {
          idArray.forEach(_id => {
            try {
              const payload = {
                category: item.value
              }
              this.deleteCaseModelCategoryInCaseModel({
                _id,
                payload
              })
            } catch (e) {
              this.error = e
              console.error(this.error, 'error')
            } finally {
              this.loading = false
            }
          })
        }
      }
    },
    removeField (i, field) {
      field.splice(i, 1)
    },
    onEditCaseModelsCategoriesEvent (actualTab) {
      this.actualTab = actualTab
      this.dialog = true
    },
    clearInput (index) {
      this.availableCategories[index].value = ''
    },
    closeDialog () {
      if (this.disabledRule) {
        this.addToast({
          title: this.$t('warning|no_empty_fields_allowed'),
          snackbarColor: 'warning',
          color: 'white'
        })
      } else {
        this.dialog = false
      }
    }
  }
}

</script>
